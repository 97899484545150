import React, { useEffect, useState } from "react";
import { Admin, DataProvider, Resource } from "react-admin";
import buildGraphQLProvider from "./data-provider/graphqlDataProvider";
import { theme } from "./theme/theme";
import Login from "./Login";
import "./App.scss";
import Dashboard from "./pages/Dashboard";
import { UserList } from "./user/UserList";
import { UserCreate } from "./user/UserCreate";
import { UserEdit } from "./user/UserEdit";
import { UserShow } from "./user/UserShow";
import { ProviderList } from "./provider/ProviderList";
import { ProviderCreate } from "./provider/ProviderCreate";
import { ProviderEdit } from "./provider/ProviderEdit";
import { ProviderShow } from "./provider/ProviderShow";
import { EmailConfigList } from "./emailConfig/EmailConfigList";
import { EmailConfigCreate } from "./emailConfig/EmailConfigCreate";
import { EmailConfigEdit } from "./emailConfig/EmailConfigEdit";
import { EmailConfigShow } from "./emailConfig/EmailConfigShow";
import { RampUpStatList } from "./rampUpStat/RampUpStatList";
import { RampUpStatCreate } from "./rampUpStat/RampUpStatCreate";
import { RampUpStatEdit } from "./rampUpStat/RampUpStatEdit";
import { RampUpStatShow } from "./rampUpStat/RampUpStatShow";
import { DeliverabilityScoreList } from "./deliverabilityScore/DeliverabilityScoreList";
import { DeliverabilityScoreCreate } from "./deliverabilityScore/DeliverabilityScoreCreate";
import { DeliverabilityScoreEdit } from "./deliverabilityScore/DeliverabilityScoreEdit";
import { DeliverabilityScoreShow } from "./deliverabilityScore/DeliverabilityScoreShow";
import { MailboxList } from "./mailbox/MailboxList";
import { MailboxCreate } from "./mailbox/MailboxCreate";
import { MailboxEdit } from "./mailbox/MailboxEdit";
import { MailboxShow } from "./mailbox/MailboxShow";
import { jwtAuthProvider } from "./auth-provider/ra-auth-jwt";

const App = (): React.ReactElement => {
  const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);
  useEffect(() => {
    buildGraphQLProvider
      .then((provider: any) => {
        setDataProvider(() => provider);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);
  if (!dataProvider) {
    return <div>Loading</div>;
  }
  return (
    <div className="App">
      <Admin
        title={"User"}
        dataProvider={dataProvider}
        authProvider={jwtAuthProvider}
        theme={theme}
        dashboard={Dashboard}
        loginPage={Login}
      >
        <Resource
          name="User"
          list={UserList}
          edit={UserEdit}
          create={UserCreate}
          show={UserShow}
        />
        <Resource
          name="Provider"
          list={ProviderList}
          edit={ProviderEdit}
          create={ProviderCreate}
          show={ProviderShow}
        />
        <Resource
          name="EmailConfig"
          list={EmailConfigList}
          edit={EmailConfigEdit}
          create={EmailConfigCreate}
          show={EmailConfigShow}
        />
        <Resource
          name="RampUpStat"
          list={RampUpStatList}
          edit={RampUpStatEdit}
          create={RampUpStatCreate}
          show={RampUpStatShow}
        />
        <Resource
          name="DeliverabilityScore"
          list={DeliverabilityScoreList}
          edit={DeliverabilityScoreEdit}
          create={DeliverabilityScoreCreate}
          show={DeliverabilityScoreShow}
        />
        <Resource
          name="Mailbox"
          list={MailboxList}
          edit={MailboxEdit}
          create={MailboxCreate}
          show={MailboxShow}
        />
      </Admin>
    </div>
  );
};

export default App;
