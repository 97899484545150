import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
} from "react-admin";

import { EMAILCONFIG_TITLE_FIELD } from "../emailConfig/EmailConfigTitle";
import { PROVIDER_TITLE_FIELD } from "../provider/ProviderTitle";
import { RAMPUPSTAT_TITLE_FIELD } from "../rampUpStat/RampUpStatTitle";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const MailboxShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="Access Token" source="accessToken" />
        <DateField source="createdAt" label="Created At" />
        <TextField label="Email" source="email" />
        <ReferenceField
          label="Email Configs"
          source="emailconfig.id"
          reference="EmailConfig"
        >
          <TextField source={EMAILCONFIG_TITLE_FIELD} />
        </ReferenceField>
        <TextField
          label="Emails Sent In Last Hour"
          source="emailsSentInLastHour"
        />
        <TextField label="Emails Sent Today" source="emailsSentToday" />
        <TextField label="ID" source="id" />
        <BooleanField label="Is Linked" source="isLinked" />
        <TextField label="Last Email Sent At" source="lastEmailSentAt" />
        <TextField label="Linked At" source="linkedAt" />
        <ReferenceField
          label="Provider"
          source="provider.id"
          reference="Provider"
        >
          <TextField source={PROVIDER_TITLE_FIELD} />
        </ReferenceField>
        <TextField label="Provider Profile Id" source="providerProfileId" />
        <ReferenceField
          label="Ramp Up Stats"
          source="rampupstat.id"
          reference="RampUpStat"
        >
          <TextField source={RAMPUPSTAT_TITLE_FIELD} />
        </ReferenceField>
        <TextField label="Refresh Token" source="refreshToken" />
        <TextField label="Token Expiry" source="tokenExpiry" />
        <DateField source="updatedAt" label="Updated At" />
        <ReferenceField label="User" source="user.id" reference="User">
          <TextField source={USER_TITLE_FIELD} />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};
