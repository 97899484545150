import * as React from "react";

import {
  List,
  Datagrid,
  ListProps,
  DateField,
  TextField,
  BooleanField,
  ReferenceField,
} from "react-admin";

import Pagination from "../Components/Pagination";
import { MAILBOX_TITLE_FIELD } from "../mailbox/MailboxTitle";

export const EmailConfigList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"EmailConfigs"}
      perPage={50}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <DateField source="createdAt" label="Created At" />
        <TextField label="Delay Between Emails" source="delayBetweenEmails" />
        <BooleanField label="Dkim Configured" source="dkimConfigured" />
        <BooleanField label="Dmarc Configured" source="dmarcConfigured" />
        <TextField label="Email Signature" source="emailSignature" />
        <TextField label="ID" source="id" />
        <TextField label="Look Alike Domain" source="lookAlikeDomain" />
        <ReferenceField
          label="Mailboxes"
          source="mailbox.id"
          reference="Mailbox"
        >
          <TextField source={MAILBOX_TITLE_FIELD} />
        </ReferenceField>
        <TextField label="Opt Out Message" source="optOutMessage" />
        <TextField label="Sending Limit Per Day" source="sendingLimitPerDay" />
        <TextField
          label="Sending Limit Per Hour"
          source="sendingLimitPerHour"
        />
        <BooleanField label="Spf Configured" source="spfConfigured" />
        <BooleanField label="Track Opens" source="trackOpens" />
        <TextField label="Tracking Subdomain" source="trackingSubdomain" />
        <DateField source="updatedAt" label="Updated At" />
      </Datagrid>
    </List>
  );
};
