import * as React from "react";

import {
  Edit,
  SimpleForm,
  EditProps,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  BooleanInput,
  DateTimeInput,
} from "react-admin";

import { EmailConfigTitle } from "../emailConfig/EmailConfigTitle";
import { ProviderTitle } from "../provider/ProviderTitle";
import { RampUpStatTitle } from "../rampUpStat/RampUpStatTitle";
import { UserTitle } from "../user/UserTitle";

export const MailboxEdit = (props: EditProps): React.ReactElement => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput label="Access Token" source="accessToken" />
        <TextInput label="Email" source="email" type="email" />
        <ReferenceInput
          source="emailConfigs.id"
          reference="EmailConfig"
          label="Email Configs"
        >
          <SelectInput optionText={EmailConfigTitle} />
        </ReferenceInput>
        <NumberInput
          step={1}
          label="Emails Sent In Last Hour"
          source="emailsSentInLastHour"
        />
        <NumberInput
          step={1}
          label="Emails Sent Today"
          source="emailsSentToday"
        />
        <BooleanInput label="Is Linked" source="isLinked" />
        <DateTimeInput label="Last Email Sent At" source="lastEmailSentAt" />
        <DateTimeInput label="Linked At" source="linkedAt" />
        <ReferenceInput
          source="provider.id"
          reference="Provider"
          label="Provider"
        >
          <SelectInput optionText={ProviderTitle} />
        </ReferenceInput>
        <TextInput label="Provider Profile Id" source="providerProfileId" />
        <ReferenceInput
          source="rampUpStats.id"
          reference="RampUpStat"
          label="Ramp Up Stats"
        >
          <SelectInput optionText={RampUpStatTitle} />
        </ReferenceInput>
        <TextInput label="Refresh Token" source="refreshToken" />
        <DateTimeInput label="Token Expiry" source="tokenExpiry" />
        <ReferenceInput source="user.id" reference="User" label="User">
          <SelectInput optionText={UserTitle} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
