import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";

import { RampUpStatTitle } from "../rampUpStat/RampUpStatTitle";

export const DeliverabilityScoreCreate = (
  props: CreateProps
): React.ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm>
        <NumberInput label="Bounce Rate" source="bounceRate" />
        <NumberInput label="Click Rate" source="clickRate" />
        <NumberInput
          step={1}
          label="Emails Delivered"
          source="emailsDelivered"
        />
        <NumberInput label="Open Rate" source="openRate" />
        <ReferenceInput
          source="rampUpStat.id"
          reference="RampUpStat"
          label="Ramp Up Stat"
        >
          <SelectInput optionText={RampUpStatTitle} />
        </ReferenceInput>
        <NumberInput label="Reply Rate" source="replyRate" />
        <NumberInput step={1} label="Score" source="score" />
        <NumberInput label="Spam Block Rate" source="spamBlockRate" />
        <TextInput label="Suggestions" source="suggestions" />
        <NumberInput label="Unsubscribe Rate" source="unsubscribeRate" />
        <NumberInput step={1} label="Week" source="week" />
      </SimpleForm>
    </Create>
  );
};
