import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  ShowProps,
  TextField,
  DateField,
  ReferenceField,
} from "react-admin";
import { RAMPUPSTAT_TITLE_FIELD } from "../rampUpStat/RampUpStatTitle";

export const DeliverabilityScoreShow = (
  props: ShowProps
): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="Bounce Rate" source="bounceRate" />
        <TextField label="Click Rate" source="clickRate" />
        <DateField source="createdAt" label="Created At" />
        <TextField label="Emails Delivered" source="emailsDelivered" />
        <TextField label="ID" source="id" />
        <TextField label="Open Rate" source="openRate" />
        <ReferenceField
          label="Ramp Up Stat"
          source="rampupstat.id"
          reference="RampUpStat"
        >
          <TextField source={RAMPUPSTAT_TITLE_FIELD} />
        </ReferenceField>
        <TextField label="Reply Rate" source="replyRate" />
        <TextField label="Score" source="score" />
        <TextField label="Spam Block Rate" source="spamBlockRate" />
        <TextField label="Suggestions" source="suggestions" />
        <TextField label="Unsubscribe Rate" source="unsubscribeRate" />
        <DateField source="updatedAt" label="Updated At" />
        <TextField label="Week" source="week" />
      </SimpleShowLayout>
    </Show>
  );
};
