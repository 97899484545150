import * as React from "react";

import {
  Edit,
  SimpleForm,
  EditProps,
  NumberInput,
  BooleanInput,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

import { MailboxTitle } from "../mailbox/MailboxTitle";

export const EmailConfigEdit = (props: EditProps): React.ReactElement => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <NumberInput
          step={1}
          label="Delay Between Emails"
          source="delayBetweenEmails"
        />
        <BooleanInput label="Dkim Configured" source="dkimConfigured" />
        <BooleanInput label="Dmarc Configured" source="dmarcConfigured" />
        <TextInput label="Email Signature" source="emailSignature" />
        <TextInput label="Look Alike Domain" source="lookAlikeDomain" />
        <ReferenceInput
          source="mailboxes.id"
          reference="Mailbox"
          label="Mailboxes"
        >
          <SelectInput optionText={MailboxTitle} />
        </ReferenceInput>
        <TextInput label="Opt Out Message" source="optOutMessage" />
        <NumberInput
          step={1}
          label="Sending Limit Per Day"
          source="sendingLimitPerDay"
        />
        <NumberInput
          step={1}
          label="Sending Limit Per Hour"
          source="sendingLimitPerHour"
        />
        <BooleanInput label="Spf Configured" source="spfConfigured" />
        <BooleanInput label="Track Opens" source="trackOpens" />
        <TextInput label="Tracking Subdomain" source="trackingSubdomain" />
      </SimpleForm>
    </Edit>
  );
};
