import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  TextField,
  DateField,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  BooleanField,
} from "react-admin";

import { RAMPUPSTAT_TITLE_FIELD } from "./RampUpStatTitle";
import { EMAILCONFIG_TITLE_FIELD } from "../emailConfig/EmailConfigTitle";
import { PROVIDER_TITLE_FIELD } from "../provider/ProviderTitle";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const RampUpStatShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="Bounce Rate" source="bounceRate" />
        <DateField source="createdAt" label="Created At" />
        <TextField label="End Date" source="endDate" />
        <TextField label="ID" source="id" />
        <TextField label="Open Rate" source="openRate" />
        <TextField label="Reply Rate" source="replyRate" />
        <TextField label="Spam Block Rate" source="spamBlockRate" />
        <DateField source="startDate" label="Start Date" />
        <DateField source="updatedAt" label="Updated At" />
        <ReferenceManyField
          reference="DeliverabilityScore"
          target="rampUpStatId"
          label="DeliverabilityScores"
        >
          <Datagrid rowClick="show">
            <TextField label="Bounce Rate" source="bounceRate" />
            <TextField label="Click Rate" source="clickRate" />
            <DateField source="createdAt" label="Created At" />
            <TextField label="Emails Delivered" source="emailsDelivered" />
            <TextField label="ID" source="id" />
            <TextField label="Open Rate" source="openRate" />
            <ReferenceField
              label="Ramp Up Stat"
              source="rampupstat.id"
              reference="RampUpStat"
            >
              <TextField source={RAMPUPSTAT_TITLE_FIELD} />
            </ReferenceField>
            <TextField label="Reply Rate" source="replyRate" />
            <TextField label="Score" source="score" />
            <TextField label="Spam Block Rate" source="spamBlockRate" />
            <TextField label="Suggestions" source="suggestions" />
            <TextField label="Unsubscribe Rate" source="unsubscribeRate" />
            <DateField source="updatedAt" label="Updated At" />
            <TextField label="Week" source="week" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          reference="Mailbox"
          target="rampUpStatsId"
          label="Mailboxes"
        >
          <Datagrid rowClick="show">
            <TextField label="Access Token" source="accessToken" />
            <DateField source="createdAt" label="Created At" />
            <TextField label="Email" source="email" />
            <ReferenceField
              label="Email Configs"
              source="emailconfig.id"
              reference="EmailConfig"
            >
              <TextField source={EMAILCONFIG_TITLE_FIELD} />
            </ReferenceField>
            <TextField
              label="Emails Sent In Last Hour"
              source="emailsSentInLastHour"
            />
            <TextField label="Emails Sent Today" source="emailsSentToday" />
            <TextField label="ID" source="id" />
            <BooleanField label="Is Linked" source="isLinked" />
            <TextField label="Last Email Sent At" source="lastEmailSentAt" />
            <TextField label="Linked At" source="linkedAt" />
            <ReferenceField
              label="Provider"
              source="provider.id"
              reference="Provider"
            >
              <TextField source={PROVIDER_TITLE_FIELD} />
            </ReferenceField>
            <TextField label="Provider Profile Id" source="providerProfileId" />
            <ReferenceField
              label="Ramp Up Stats"
              source="rampupstat.id"
              reference="RampUpStat"
            >
              <TextField source={RAMPUPSTAT_TITLE_FIELD} />
            </ReferenceField>
            <TextField label="Refresh Token" source="refreshToken" />
            <TextField label="Token Expiry" source="tokenExpiry" />
            <DateField source="updatedAt" label="Updated At" />
            <ReferenceField label="User" source="user.id" reference="User">
              <TextField source={USER_TITLE_FIELD} />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
