import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  DateTimeInput,
} from "react-admin";

import { DeliverabilityScoreTitle } from "../deliverabilityScore/DeliverabilityScoreTitle";
import { MailboxTitle } from "../mailbox/MailboxTitle";

export const RampUpStatCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm>
        <NumberInput label="Bounce Rate" source="bounceRate" />
        <ReferenceArrayInput
          source="deliverabilityScore"
          reference="DeliverabilityScore"
          parse={(value: any) => value && value.map((v: any) => ({ id: v }))}
          format={(value: any) => value && value.map((v: any) => v.id)}
        >
          <SelectArrayInput optionText={DeliverabilityScoreTitle} />
        </ReferenceArrayInput>
        <DateTimeInput label="End Date" source="endDate" />
        <ReferenceArrayInput
          source="mailboxes"
          reference="Mailbox"
          parse={(value: any) => value && value.map((v: any) => ({ id: v }))}
          format={(value: any) => value && value.map((v: any) => v.id)}
        >
          <SelectArrayInput optionText={MailboxTitle} />
        </ReferenceArrayInput>
        <NumberInput label="Open Rate" source="openRate" />
        <NumberInput label="Reply Rate" source="replyRate" />
        <NumberInput label="Spam Block Rate" source="spamBlockRate" />
      </SimpleForm>
    </Create>
  );
};
